/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/dist/css/bootstrap.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@font-face {
    font-family: "SourceSansPro-Bold";
    src: url(assets/fonts/SourceSansPro-Bold.ttf) format("opentype");
}

@font-face {
    font-family: "SourceSansPro-Regular";
    src: url(assets/fonts/SourceSansPro-Regular.ttf) format("opentype");
}

@font-face {
    font-family: "SourceSansPro-SemiBold";
    src: url(assets/fonts/SourceSansPro-SemiBold.ttf) format("opentype");
}


@font-face {
    font-family:"neultica" ;
    src: url(assets/fonts/neultica-4f-scratched-black.ttf) format("opentype");
  }

  @font-face {
    font-family:"SFProDisplay-Bold" ;
    src: url(assets/fonts/FontsFree-Net-SFProDisplay-Bold.ttf) format("opentype");
  }
  
/* h3 {
    font-family: 'SourceSansPro-Bold';
    font-size: 26px;
    font-weight: bold;
    color: #17171F;
    text-transform: uppercase;
}
 */
html,
body {
    min-height: 100vh;
    height: 100%;
    margin: 0;
}

@media all and (max-width:745.33px) {
    h3 {
        font-size: 22px !important;
        margin-bottom: 32px !important;
        text-align: center;
    }
}

:host ::ng-deep.modal.show .modal-dialog {
    margin: 24px !important;
}